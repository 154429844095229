import React from 'react';
import './Video.css';

const Video = (props) => {
  return (
    <div className='parallax_video'>
      <div className='jarallax' data-jarallax-video={'mp4:./mov/' + props.mov}>
        <div className='video-table'>
          <div className='table-cell'></div>
        </div>
      </div>
    </div>
  );
};

export default Video;
