import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import './Location.css';

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 43.05206,
      lng: 17.64598,
    },
    zoom: 18,
  };

  render() {
    return (
      <div className='info_panel'>
        <div id='p3' className='info_panel_adresa'>
          <h3>Adresa:</h3>
          <p>Pizzeria Milano</p>
          <p>Sportska b.b.</p>
          <p>20350 Metković</p>
          <p>Tel.: +385 20 688 888</p>
          <br />
          <h3>Radno brijeme:</h3>
          <p>Ponedjeljak - Petak: 09:30 - 23:00</p>
          <p>Subota: 09:30 - 22:30</p>
          <p>Nedjelja: 10:30 - 21:30</p>
          <br />
        </div>
        <div className='info_panel_maps'>
          <div className='maps'>
            <iframe
              title='Pizzeria milano'
              frameBorder='0'
              width='100%'
              height='400px'
              scrolling='yes'
              marginHeight='0'
              marginWidth='0'
              src='https://maps.google.com/maps?width=360&amp;height=400&amp;hl=en&amp;q=pizzeria milano, metkovic, dubrovačko neretvanska &amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleMap;
