import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import Menu from './navigation/Navigation';
import Body from './body/Body';
import Slider from './components/Menu';

function App() {
  const [ShowMenu, setShowMenu] = useState(false);

  const showMenuHandler = (e) => {
    setShowMenu(e);
  };

  return (
    <Router>
      <Menu menuOption={ShowMenu} onSelect={showMenuHandler} />
      <Switch>
        <Redirect exact from='/' to='/home' />
        <Route path='/home'>
          <Body />
        </Route>
        <Route path='/menu'>
          <Slider />
        </Route>
      </Switch>
    </Router>

    /*
    <div>
      <Menu menuOption={ShowMenu} onSelect={showMenuHandler} />
      {ShowMenu || state.redirect != null ? <Slider /> : <Body />}
    </div>*/
  );
}

export default App;
