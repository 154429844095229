import React from 'react';
import Video from './Video';
import Location from './Location';
import './Body.css';

const back1 = [
  { file: './images/prostor-overlay.jpg' },
  { file: './images/image1.jpg' },
];

const Body = () => {
  return (
    <div className='body_main'>
      <div
        id='p1'
        className='parallax1'
        style={{
          backgroundImage: `url(${back1[0].file})`,
        }}
      />
      <div className='text_center'>
        <div className='text1'>Dobro došli!</div>
      </div>
      <div className='text_center'>
        <div className='text2'>
          Dugogodišnjom i uspješnom ugostiteljskom tradicijom uz sve ove godine
          poslovanja, pizzeria Milano je postala jedno od prepoznatljivih
          gastronomskih mjesta u široj okolici Metkovića.
          <br />
          Nudimo veliki izbor pizza iz krušne peći i ostalih raznovrsnih jela u
          ugodnoj i opuštenoj atmosferi.
        </div>
      </div>
      <div className='divider'>
        <div className='divider_separator'>
          <div className='divider_icon'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 -1 640.99871 640'
            >
              <path
                d='M440.45,254.18c2.26,1.07,4.18,2.07,6.18,2.88c0.73,0.29,2.04,0.51,2.41,0.12c1.86-1.98,3.17-0.84,4.6,0.47
		c26.27,23.96,52.63,47.82,78.79,71.9c18.61,17.13,37.02,34.48,55.34,51.93c2.9,2.76,5.44,6.26,7.1,9.9
		c4.25,9.24,0.79,20.16-7.86,26.71c-8.37,6.32-19.28,6.67-27.15-0.3c-7.74-6.85-14.83-14.48-21.87-22.07
		c-21.62-23.29-43.13-46.69-64.62-70.11c-13.64-14.86-27.18-29.8-40.69-44.77c-1.45-1.6-3.89-3.33-1.17-5.97
		c0.28-0.27,0.05-1.3-0.2-1.86c-0.98-2.17-2.05-4.29-3.09-6.41c-1.61,0.11-3.18,0.07-4.69,0.34c-3.92,0.7-5.34-2.72-7.48-4.65
		c-27.19-24.62-55.03-48.58-79.21-76.3c-20-22.93-40.48-45.4-62.24-66.68c-12.21-11.94-24.61-23.68-36.9-35.55
		c-1.8-1.74-3.48-3.62-5.06-5.55c-3.6-4.43-2.77-9.17,2.14-12.1c4.71-2.8,10.01-3.29,14.86-1.41c7.28,2.82,14.38,6.26,21.21,10.07
		c25.19,14.04,46.88,32.64,67.36,52.74c27.35,26.84,53.13,55.13,77.66,84.58c7.91,9.49,15.07,19.43,20.71,30.44
		c0.52,1.02,2.2,1.48,2.64,2.51c0.85,1.99,1.31,4.18,1.71,6.32C441.09,252.19,440.65,253.12,440.45,254.18z'
              />
              <path
                d='M579.3,75.56c-19.78,20.77-39.46,41.64-59.17,62.48c-0.35,0.36-0.67,0.77-0.99,1.17c-1.92,2.37-2.37,4.93-0.14,7.23
		c2.27,2.34,4.99,1.9,7.2-0.08c4.94-4.42,9.71-9.01,14.53-13.56c22.78-21.54,45.56-43.08,68.76-64.21
		c-7.41,7.72-14.81,15.46-22.25,23.17c-18.15,18.8-36.32,37.58-54.48,56.39c-0.96,1-2.4,2.15-2.46,3.29
		c-0.13,2.2-0.03,4.84,1.09,6.57c1.48,2.29,4.27,2.08,6.51,0.59c1.57-1.05,2.95-2.42,4.33-3.73c24.93-23.71,49.83-47.44,74.77-71.14
		c1.34-1.27,2.91-2.29,4.38-3.44c0.22,0.26,0.44,0.51,0.66,0.76c-14.24,14.94-28.41,29.95-42.75,44.8
		c-20.17,20.9-40.57,41.57-60.61,62.59c-3.87,4.05-7.44,5.81-13.23,5.07c-11.57-1.47-22.44,1.34-31.35,9.16
		c-13.59,11.94-27.31,23.78-40.17,36.48c-19.87,19.61-39.23,39.74-58.46,59.98c-17.71,18.64-32.19,39.83-46.45,61.13
		c-7.08,10.57-13.41,21.65-20.36,32.31c-6.56,10.07-13.74,19.65-23.84,26.58c-17.54,12.03-42.2,0.58-44.31-20.69
		c-0.96-9.68,3.98-17.15,10.04-23.96c8.4-9.46,18.88-16.22,29.6-22.72c33.42-20.27,65.67-42.21,93.9-69.4
		c25.15-24.23,49.79-49.01,74.4-73.8c5.9-5.94,11.14-12.62,16.15-19.36c7.06-9.5,8.84-20.5,7.21-32c-0.51-3.65,0.58-5.86,3.11-8.31
		c23.63-22.88,47.06-45.98,70.73-68.82c12.89-12.44,26.15-24.5,39.24-36.73c0.24,0.21,0.46,0.42,0.7,0.62
		c-2.77,3.24-5.4,6.6-8.31,9.68c-17.8,18.79-35.65,37.53-53.51,56.25c-4.67,4.89-9.49,9.62-14.22,14.46
		c-0.88,0.9-1.71,1.89-2.44,2.93c-1.75,2.53-1.78,5.03,0.56,7.22c2.32,2.17,4.99,2.12,7.3,0.23c3.16-2.58,6.08-5.43,9.01-8.27
		c20.88-20.23,41.74-40.47,62.58-60.73c3.37-3.28,7.19-6.1,10.52-9.42'
              />
            </svg>
          </div>
        </div>
      </div>
      <div className='text_center'>
        <div className='text1'>Pogledajte naš jelovnik</div>
      </div>
      <div className='btn_container'>
        <div className='btn_jelovnik'>Jelovnik</div>
      </div>

      <div
        className='parallax2'
        style={{
          backgroundImage: `url(${back1[1].file})`,
        }}
      />
      <div className='text_container'>
        <div className='text2'>
          <h3>Želite potpuni obrok?</h3>
          Pizzeria Milano Vam svojom gastronomskom ponudom pruža uživanje u
          potpunom obroku u nekoliko slijedova. U našoj bogatoj ponudi glavnih
          jela izaberite nešto po Vašem ukusu, a sve skupa upotpunite slasnim
          desertom na kraju.
          <br />
          <br />
          Dobar tek želi Vam Pizzeria Milano!
        </div>
      </div>
      <Video mov='pizza1.mov' />
      <Location />
      <div className='mreze'>
        <div className='text1'>Pratite nas</div>
        <div className='text3'>na društvenim mrežama</div>
        <div className='main_logo'>
          <img
            src='images/logo1.png'
            alt=''
            className='logo_regular'
            width='120px'
          />
        </div>
        <div className='text1 gold'>★★★★★</div>
        <div className='main_menu_media'>
          <a href='https://www.facebook.com/Pizzeria-Milano-Metkovi%C4%87-178566429017669/?ref=page_internal'>
            <i className='fa fa-facebook '></i>
          </a>
          <a href='https://www.instagram.com/explore/locations/924116120/pizzeria-milano-metkovic/'>
            <i className='fa fa-instagram '></i>
          </a>
          <a href='https://www.tripadvisor.com/Restaurants-g1009398-c31-Metkovic_Dubrovnik_Neretva_County_Dalmatia.html'>
            <i className='fa fa-tripadvisor '></i>
          </a>
        </div>
        <div className='text3'>
          <i className='fa fa-phone'></i> +385 20 688 888
        </div>
        <div className='footer'>
          <div className='left'>© PIZZERIA MILANO</div>
          <div className='right'>
            Web by
            <a href='mailto:asko.murtezani@gmail.com'> Asko</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
