import React, { useState } from 'react';
import './Navigation.css';

const Navigation = (props) => {
  const [SelectedItem, setSelected] = useState();

  const onSlectHandler = (e) => {
    props.onSelect(e);
  };

  return (
    <header className='top_section'>
      <div className='navigation_row'>
        <div className='logo'>
          <div className='logo_shadow'></div>
          <a
            href='/home'
            onClick={() => {
              onSlectHandler(false);
            }}
          >
            <img
              src='images/logo1.png'
              alt=''
              className='logo_regular'
              width='120px'
            />
          </a>
        </div>
        <div className='nav_container'>
          <nav className='nav_menu_main'>
            <ul className='nav_menu_element'>
              <li className='nav_menu_item'>
                <a
                  href='/home'
                  onClick={() => {
                    onSlectHandler(false);
                  }}
                  className='menu_item_type'
                >
                  HOME
                </a>
              </li>
              <li className='nav_menu_item'>
                <a
                  href='/menu'
                  onClick={() => {
                    onSlectHandler(true);
                  }}
                  className='menu_item_type'
                >
                  MENU
                </a>
              </li>
              <li className='nav_menu_item'>
                <a
                  href='/home#p3'
                  onClick={() => {
                    onSlectHandler(false);
                  }}
                  className='menu_item_type'
                >
                  KONTAKT
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className='nav_menu_media'>
          <a href='https://www.facebook.com/Pizzeria-Milano-Metkovi%C4%87-178566429017669/?ref=page_internal'>
            <i className='fa fa-facebook'></i>
          </a>
          <a href='https://www.instagram.com/explore/locations/924116120/pizzeria-milano-metkovic/'>
            <i className='fa fa-instagram'></i>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
