import css from './Menu.models.css';

const Menu = (props) => {
  const state = [
    { name: './images/menu/Milano_01.jpg' },
    { name: './images/menu/Milano_02.jpg' },
    { name: './images/menu/Milano_03.jpg' },
    { name: './images/menu/Milano_04.jpg' },
    { name: './images/menu/Milano_05.jpg' },
    { name: './images/menu/Milano_06.jpg' },
    { name: './images/menu/Milano_07.jpg' },
    { name: './images/menu/Milano_08.jpg' },
    { name: './images/menu/Milano_09.jpg' },
    { name: './images/menu/Milano_10.jpg' },
    { name: './images/menu/Milano_11.jpg' },
    { name: './images/menu/Milano_12.jpg' },
    { name: './images/menu/Milano_13.jpg' },
    { name: './images/menu/Milano_14.jpg' },
    { name: './images/menu/Milano_15.jpg' },
    { name: './images/menu/Milano_16.jpg' },
  ];

  return (
    <div className='MenuPage'>
      {state.map((src, ndx) => {
        return (
          <div className='Page' id={'#mnu' + ndx}>
            <img
              alt=''
              key={src.name}
              src={src.name}
              width='100%'
              height='100%'
            />
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
